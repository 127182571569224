class PhotoDocumentItem {
  constructor ($http, ErrFactory, LoaderFactory) {
    Object.defineProperties(this, {
      $http:         { value: $http         },
      errFactory:    { value: ErrFactory    },
      loaderFactory: { value: LoaderFactory }
    });
  }

  loadImage() {
    this.loaderFactory.show();
    Promise.resolve()
    .then(() => this.$http({
      url: this.photoDocument.attachment_url,
      method: 'GET',
      responseType: 'blob'
    }))
    .then(response => {
      let blob = response.data;
      return URL.createObjectURL(blob);
    })
    .then(res => this.photoDocument.image = res)
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  select(activeDocument) {
    if (!this.photoDocument.image) this.loadImage();
    this.onSelect({ activeDocument });
  }

  collapsePD() {
    if (!this.photoDocument.image) this.loadImage();
    this.photoDocument.collapsed = !this.photoDocument.collapsed;
  }

  getName() {
    let type = this.types.find(type => type.name === this.photoDocument.subject);
    let name = this.currentLang ? type.name_translations[this.currentLang] : type.name;
    return name || this.photoDocument.subject;
  }

}

app.component('photoDocumentItem', {
  template: require('scripts/components/time-trackings/photo-document/pd-list/pd-list-item/pd-list-item.html'),
  controller: PhotoDocumentItem,
  bindings: {
    photoDocument: '<',
    onSelect:      '&',
    types:         '<',
    currentLang:   '<'
  }

});
