class JobConfirm {
  $scope:       any;
  $element:     any;
  $location:    any;
  notification: any;

  current: any;
  options: any;
  onShow:  Function;
  constructor ($scope, $element, $location, Notification) {
    Object.defineProperties(this, {
      $scope:       { value: $scope       },
      $element:     { value: $element     },
      $location:    { value: $location    },
      notification: { value: Notification },
    });
  }

  hide(): Promise<void> {
    this.$element.remove();
    if (!this.current.confirmed) return Promise.resolve()
    .then(() => this.notification.confirm_p({
      title: 'note',
      desc: 'timeTrackings.ebsNotConfirmed',
      buttons: ['cancel','open']
    }))
    .then((res) => {
      if (res === 2) this.$location.path(`/ebs/${this.current.id}/max`);
      else {
        if (this.options) this.show();
        else this.$location.path('/');
        this.$scope.$apply()
      }
    });
  }

  show(): void {
    this.$element.remove();
    this.onShow();
  }
}

window.app.component('jobConfirm', {
  template: require('scripts/components/time-trackings/time-tracking/job-confirm.html'),
  controller: ['$scope', '$element', '$location', 'Notification', JobConfirm],
  bindings: {
    current: '<',
    options: '<',
    onShow: '&'
  }
});
