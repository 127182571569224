class TTDateInput {
    constructor ($element) {
        this.$element = $element;
        this.dateFormat = localStorage.getItem('short_date_format') || 'dd.MM.yy';
        this.dateSelected = true;
    }

    onSelectDate () {
        this.dateSelected = true;
    }
    
    onChangeDate () {
        this.dateSelected = false;
    }
}

app.component('ttDateInput', {
    template: require('scripts/components/time-trackings/tt-date-input/tt-date-input.html'),
    controller: TTDateInput,
    bindings: {
        ngModel: '=',
        ngDisabled: '=',
        ngChange: '='
    }
});