class TTPause {
  constructor ($scope, ValidationErrorFactory) {
    Object.defineProperty(this, 'validationErrorFactory', { value: ValidationErrorFactory });
    Object.defineProperty(this, '$scope',                 { value: $scope                 });

    this.$scope.$watch('$ctrl.reloadTrigger', (newVal, oldVal) => { if (newVal !== oldVal) this.reloadPause();  }, true);
    this.$scope.$watch('$ctrl.statesTrigger', (newVal, oldVal) => { if (newVal !== oldVal) this.reloadStates(); }, true);
  }

  delete(e) {
    e.stopPropagation();
    this.onDelete({pause: this.ngModel});
  }

  reloadPause() {
    if (this.ngModel.end && this.ngModel.start) {
      this.ngModel.start.setDate(    this.ngModel.tt.start_date.getDate());
      this.ngModel.start.setMonth(   this.ngModel.tt.start_date.getMonth());
      this.ngModel.start.setFullYear(this.ngModel.tt.start_date.getFullYear());
      this.ngModel.end.setDate(      this.ngModel.tt.start_date.getDate());
      this.ngModel.end.setMonth(     this.ngModel.tt.start_date.getMonth());
      this.ngModel.end.setFullYear(  this.ngModel.tt.start_date.getFullYear());

      if (this.ngModel.start < this.ngModel.tt.start_date) this.ngModel.start.setDate(this.ngModel.tt.start_date.getDate() + 1);
      if (this.ngModel.end   < this.ngModel.tt.start_date) this.ngModel.end.setDate(  this.ngModel.tt.start_date.getDate() + 1);
    }
  }

  validate() {
    this.reloadPause();
    this.onValidate();
  }

  reloadStates() {
    this.errorList = {};
    if (this.ngModel.errors && this.ngModel.errors.length) {
      let fullPauseErrors = this.ngModel.errors.filter(e =>  !e.pauseStart && !e.pauseEnd);
      if (fullPauseErrors.length) {
        this.errorList.techErr  = fullPauseErrors.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        this.errorList.legalErr = fullPauseErrors.find(err => err instanceof this.validationErrorFactory.LegalError);
      }
      let startPauseError = this.ngModel.errors.filter(e => e.pauseStart);
      let endPauseError   = this.ngModel.errors.filter(e => e.pauseEnd);
      if (startPauseError.length) {
        this.errorList.techErrStart  = startPauseError.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        this.errorList.legalErrStart = startPauseError.find(err => err instanceof this.validationErrorFactory.LegalError);
      }
      if (endPauseError.length) {
        this.errorList.techErrEnd  = endPauseError.find(err => err instanceof this.validationErrorFactory.TechnicalError);
        this.errorList.legalErrEnd = endPauseError.find(err => err instanceof this.validationErrorFactory.LegalError);
      }
    }
  }
}

app.component('ttPause', {
    template: require('scripts/components/time-trackings/tt-pauses-list/tt-pause.html'),
    controller: TTPause,
    bindings: {
      ngModel:       '=',
      onDelete:      '&',
      onValidate:    '&',
      reloadTrigger: '=',
      statesTrigger: '='
    }
});
