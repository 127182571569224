class TTCustomerEmail {
  constructor($scope, $translate, API, AppFactory, LoaderFactory, ErrFactory, Notification, ValidateService) {
    Object.defineProperty(this, '$scope',          { value: $scope          });
    Object.defineProperty(this, '$translate',      { value: $translate      });
    Object.defineProperty(this, 'api',             { value: API             });
    Object.defineProperty(this, 'appFactory',      { value: AppFactory      });
    Object.defineProperty(this, 'loaderFactory',   { value: LoaderFactory   });
    Object.defineProperty(this, 'errFactory',      { value: ErrFactory      });
    Object.defineProperty(this, 'notification',    { value: Notification    });
    Object.defineProperty(this, 'validateService', { value: ValidateService });

    this.customersList = [];
    this.emailsOutsidePage = [];
  }

  openCustomerEmailPopup() {
    this.customerEmailPopup = true;
    this.checkActiveEmails();
    if (this.appFactory.isNetwork()) { 
      this.loaderFactory.show();
      this.reloadCustomerEmails()
      .then(() => this.loaderFactory.hide());
    } else this.checkActiveEmails();
  }

  reloadCustomerEmails(page = 1) {
    this.saveCustomerEmails();
    return this.api.getCustomerEmails(this.assignmentId, page)
    .then((res) => {
      this.customersList = [...res.customers];
      this.customerEmailPopupPaging = res.meta.paging;
      this.checkActiveEmails();
      this.$scope.$apply();
    })
    .catch((err) => console.error(err));
  }

  addCustomerEmail() {
    if (this.checkForFiveEmailsLimit()) return this.notification.alert({ title: 'note', desc: 'timeTrackings.notMoreThenFiveRecepientsAllowed' });
    if (!this.isEmailValid(this.customCustomerEmail)) return new this.errFactory('errors.notEmail').notify();
    let ce = { email: this.customCustomerEmail, custom: true, active: true };
    this.emailsOutsidePage.push(ce);
    this.customCustomerEmail = null;
  }

  checkActiveEmails() {
    this.emailsOutsidePage = this.emailsOutsidePage.filter(e => {
      let cl = this.customersList.find(ce => ce.email === e.email);
      if (cl) {
        if (e.active) cl.active = true;
        return false;
      }
      return true;
    })
    this.customerEmails.forEach(c => {
      let cl = this.customersList.find(ce => ce.email === c.email);
      let eop = this.emailsOutsidePage.find(eo => eo.email === c.email);
      if (cl && c.active) cl.active = true;
      else if (!eop) this.emailsOutsidePage.push(c);
    })
  }

  validateEmailLimit(e, customer) {
    if (this.checkForFiveEmailsLimit(customer)) {
      e.stopPropagation();
      this.notification.alert({ title: 'note', desc: 'timeTrackings.notMoreThenFiveRecepientsAllowed' });
    }
  }

  checkForFiveEmailsLimit(customer = null) {
    return (!customer || !customer.active) && [...this.customersList, ...this.emailsOutsidePage].filter(e => e.active).length >= 5;
  }

  saveCustomerEmails() {
    this.customerEmails = [...this.customersList.filter(c => c.active), ...this.emailsOutsidePage.filter(c => c.active)];
  }

  clearCustomerEmail() {
    this.customerEmails    = [];
    this.customersList     = [];
    this.emailsOutsidePage = [];
    this.resetCustomerEmailData();
  }

  resetCustomerEmailData() {
    this.saveCustomerEmails();

    this.customersList            = [];
    this.customerEmailPopup       = null;
    this.customerEmailPopupPaging = null;
    this.customCustomerEmail      = null;
  }

  getPages() {
    return Array.from(Array(this.customerEmailPopupPaging.total_pages).keys()).map(p => p + 1);
  }

  isEmailValid(email) {
    return email && this.validateService.isEmailValid(email);
  }

  showTooltipLimitMessage() {
    this.$translate(['timeTrackings.onlyFiveRecepientsAllowed'])
    .then((t) => this.notification.message(t['timeTrackings.onlyFiveRecepientsAllowed']));
  }

}

app.component('ttCustomerEmail', {
  template: require('scripts/components/time-trackings/tt-customer-email/tt-customer-email.html'),
  controller: TTCustomerEmail,
  bindings: {
    customerEmails: '=',
    assignmentId: '='
  }
});
