class TTNotes {
  constructor () {
    let el = document.querySelectorAll('input[maxlength], textarea[maxlength]');
    el.forEach(e => {
      e.addEventListener('keyup', function(event) {
        this.value = this.value.substr(0, this.getAttribute("maxlength"));
        this.onkeyup && this.onkeyup();
      });
    });
  }
}

app.component('ttNotes', {
    template: require('scripts/components/time-trackings/tt-notes/tt-notes.html'),
    controller: TTNotes,
    bindings: {
      ngModel: '='
    }
});
