class PhotoDocumentItemDetails {
  constructor (AuthService) {
    Object.defineProperties(this, {
      authService: { value: AuthService }
    });
  }
}

app.component('photoDocumentItemDetails', {
  template: require('scripts/components/time-trackings/photo-document/pd-list/pd-list-item-details/pd-list-item-details.html'),
  controller: PhotoDocumentItemDetails,
  bindings: {
    photoDocument: '<'
  }
});
