class PausesList {
  constructor (TimeTrackingFactory, Notification, $scope) {
    this.TimeTrackingFactory = TimeTrackingFactory;
    this.Notification = Notification;
    this.$scope = $scope;
  }

  delete(pause) {
    return this.Notification.confirm_p({
      title: 'confirm',
      desc: 'questions.deletePause',
      cancelButton: 'no',
      button: 'yes'
    }, {}).then(res => {
      if (res !== 2) return false;
      this.ngModel.pauses = this.ngModel.pauses.filter((p) => p !== pause);
      this.$scope.$apply();
      this.onValidate();
    });
  }

  add(e) {
    e.stopPropagation();
    this.onAdd();
  }

  validate() {
    this.onValidate();
  }
}

app.component('ttPausesList', {
  template: require('scripts/components/time-trackings/tt-pauses-list/tt-pauses-list.html'),
  controller: PausesList,
  bindings: {
    ngModel:       '=',
    onAdd:         '&',
    onValidate:    '&',
    reloadTrigger: '=',
    statesTrigger: '='
  }
});
