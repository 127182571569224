class PhotoDocumentNew {
  $location:     any;
  $timeout:      any;
  $scope:        any;
  config:        any;
  api:           any;
  appFactory:    any;
  loaderFactory: any;
  errFactory:    any;
  notification:  any;

  loading:         boolean;
  imageURI:        string;
  documentTypes:   any[];
  documentType:    any;
  documentComment: string;
  constructor ($location, $timeout, $scope, Config, API, AppFactory, ErrFactory, LoaderFactory, Notification) {
    Object.defineProperties(this, {
      $location:     { value: $location     },
      $timeout:      { value: $timeout      },
      $scope:        { value: $scope        },
      api:           { value: API           },
      appFactory:    { value: AppFactory    },
      config:        { value: Config        },
      errFactory:    { value: ErrFactory    },
      loaderFactory: { value: LoaderFactory },
      notification:  { value: Notification  },
    });

    let passed = localStorage.getItem('passedTutorials') ? JSON.parse(localStorage.getItem('passedTutorials')) : [];
    if (!passed.length || !passed.find(t => t === 'photo-document')) $location.path('/tutorial/photo-document');
    this.loading = false;

    // if (this.config.hostName && this.config.hostName !== 'live') {
      this.getDocumentTypes(); 
    // } else return this.$location.path('/projects');
  }

  back(): void {
    this.$location.path('/projects');
  }

  private getDocumentTypes(): void {
    this.loaderFactory.show();
    Promise.resolve(this.api.getDocumentTypes.list().$promise)
    .then((res) => {
      let currentLang = localStorage.getItem('lang');
      this.documentTypes = res.document_types.filter(i => i.active).map(type => {
        if (currentLang) type.nameTranslate = type.name_translations[currentLang] || type.name;
        return type;
      });
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  capturePhoto(): void {
    if (window.cordova) this.devicePhoto();
    else if (this.config.hostName && this.config.hostName === 'staging') this.fakeImage();
  }
 
  private devicePhoto(): void {
    this.loaderFactory.show();
    (new Promise((res,rej) => navigator.camera.getPicture(res, rej, {
      quality:            70,
      destinationType:    Camera.DestinationType.DATA_URL,
      sourceType:         Camera.PictureSourceType.CAMERA,
      encodingType:       Camera.EncodingType.JPEG,
      allowEdit:          false,
      saveToPhotoAlbum:   false,
      correctOrientation: true,
    })))
    .then((base64) => {
      this.imageURI = `data:image/jpeg;base64,${base64}`;
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  private fakeImage(): Promise<void> {
    this.loaderFactory.show();
    return this.api.getRandomImage(400, 600)
    .then((data) => {        
      this.imageURI = data;
      this.$scope.$apply();
    })
    .catch((err) => {
      console.error(err);
    })
    .then(() => {
      this.loaderFactory.hide();
    })
  }

  create(): Promise<void> {
    this.loading = true;
    this.loaderFactory.show();
    let params = {
      subject: this.documentType.name,
      message_external_employee: this.documentComment,
      attachment: this.imageURI
    };

    return this.appFactory.checkNewVersion()
    .then(() => this.api.createPhotoDocument(params))
    .then(res => this.notification.alert({
        title: 'photo_document.uploadedSuccess',
        desc: 'photo_document.uploadedSuccessMessage'
      }, () => this.$location.path('/projects'))
    )
    .catch((err) => {
      if (err && err.store_url) {
        this.$location.path('/blocker');
        this.$scope.$apply();
      }
      else if (err instanceof this.errFactory) err.notify(() => this.loading = false);
      else {
        console.error(err);
        this.loading = false;
      }
    })
    .then(() => this.loaderFactory.hide());
  }

}

window.app.component('photoDocumentNew', {
  template: require('scripts/components/time-trackings/photo-document/pd-new/pd-new.html'),
  controller: ['$location', '$timeout', '$scope', 'Config', 'API', 'AppFactory', 'ErrFactory', 'LoaderFactory', 'Notification', PhotoDocumentNew]
});
