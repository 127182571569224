class PhotoDocumentList {
  $scope:        any;
  api:           any;
  errFactory:    any;
  loaderFactory: any;

  columns: any[]  = [{ label: 'date', sort: 'created_at' }, { label: 'photo_document.documentType', sort: 'subject' }];
  column:  string;
  reverse: false;

  totalPages:  number = 0;
  page:        number = 1;
  currentLang: string;

  activeTab:      string;
  documentTypes:  any[];
  photoDocuments: any[];
  activeDocument: any;
  constructor ($scope, $rootScope, API, ErrFactory, LoaderFactory) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      api:           { value: API           },
      errFactory:    { value: ErrFactory    },
      loaderFactory: { value: LoaderFactory },
    });

    this.column  = this.columns[0].sort;
    this.reverse = true;

    Promise.resolve(this.getTypes())
    .then(() => this.fetchPhotoDocuments());
    
    this.currentLang = localStorage.getItem('lang');
    $rootScope.$on('$translateChangeSuccess', (event, current, previous) => {
      if (current.language) this.currentLang = current.language;
    });
  }

  paginationCallback(page: number): void {
    this.page = page;
    this.fetchPhotoDocuments();
  }

  sortCallback(column, reverse) {
    this.column  = column;
    this.reverse = reverse;
    this.fetchPhotoDocuments();
  }

  private getTypes(): Promise<any> {
    return Promise.resolve(this.api.getDocumentTypes.list().$promise)
    .then((res) => { this.documentTypes = res.document_types.filter(i => i.active); });
  }

  private fetchPhotoDocuments() {
    this.loaderFactory.show();
    return this.api.getPhotoDocumentsListPaginated(this.page, this.activeTab, this.column, this.reverse)
    .then((res) => {
      this.totalPages     = res.meta.paging.total_pages;
      this.photoDocuments = res.photo_documents;
      this.$scope.$apply();
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  getDisplayedState(): string {
    switch (this.activeTab) {
      case 'unread':
        return 'photo_document.submittedResults';
      case 'read':
        return 'photo_document.readResults';
      default:
        return 'photo_document.allResults';
    }
  }

  changeTab(tab): void {
    if (this.activeTab === tab) this.activeTab = null;
    else this.activeTab = tab;
    this.page = 1;
    this.fetchPhotoDocuments();
  }

  setActiveDocument(doc): void {
    this.activeDocument = doc;
  }

}

window.app.component('photoDocumentList', {
  template: require('scripts/components/time-trackings/photo-document/pd-list/pd-list.html'),
  controller: ['$scope', '$rootScope', 'API', 'ErrFactory', 'LoaderFactory', PhotoDocumentList]
});
